/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          $(".wrapper").toggleClass("noscroll");
          e.preventDefault();
        });

        $(".vendor-tab-nav li").on('click', function(e){
          if(!$(this).hasClass('active')){
            var amountType = $(this).data("value");
            $(".vendor-tab-nav li").toggleClass("active");

            if(amountType === 'yearly'){
              $("#silver .price-value").html("$7.50");
              $("#platinum .price-value").html("$18.75");
            }else{
              $("#silver .price-value").html("$10");
              $("#platinum .price-value").html("$25");
            }
          }
          
        });


        $('.fade-in-section-home').addClass('is-visible');

        var headerBar = $(".header-bar"); 

        $(window).scroll(function() {
          if ($(window).scrollTop() > 100) {
            headerBar.addClass('animate');
          } else {
            headerBar.removeClass('animate');
          }
        });

        
        $(window).scroll(function () {
            $('.fade-in-section, .fade-in-section-left, .fade-in-section-right').each(function (i) {
                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                    $(this).addClass('is-visible');
                }
            });
          });


      }
    },
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
